import React from 'react'
import './Copywrite.css';

export default function Copyright() {
  return (
    <div className="copyright">
      Copyright ©2021 All rights reserved
    </div>
  );
}
